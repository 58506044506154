import React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
//import fontawesome
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons"
import "./member-display.scss"

export default function MemberDisplay({teamMemberList}) {

    return (
        <>
        <div className="our-team-section pt-100 pb-100">
            <div className="container">
                <div className="row our-team-row">
                    {teamMemberList?.map((mb, index) => {
                        return (
                            <div className="our-team col-md-3 col-sm-6 col-xs-6 toggle-card" key={index}>
                                {mb?.team?.avatar ? 
                                <GatsbyImage
                                image={getImage(mb?.team?.avatar?.localFile)}
                                alt={"Raiders Member"}
                                className="img-player"
                                /> :
                                <StaticImage
                                src="../../images/team/2.jpg"
                                formats={["auto", "webp", "avif"]}
                                alt="Honorary Members"
                                className="img-player"
                                />
                                }
                                <div className="person-details">
                                    <div className="overly-bg"></div>
                                    <a href="team-single.html"><h5 className="person-name">{mb?.title}</h5></a>
                                    <table className="person-info">
                                        <tbody>
                                            <tr>
                                                <td>Nationality </td>
                                                <td style={{display: "flex", height: "27px", alignItems: "center"}}>
                                                    <GatsbyImage
                                                    image={getImage(mb?.team?.countryFlag?.localFile)}
                                                    alt={"Nationality"}
                                                    className="mb-flag"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Position </td>
                                                <td>{mb?.team?.position}</td>
                                            </tr>
                                            <tr>
                                                <td>Born </td>
                                                <td>{mb?.team?.birthday}</td>
                                            </tr>
                                            <tr>
                                                <td>Club </td>
                                                <td>{mb?.team?.teamName}</td>
                                            </tr>
                                            <tr>
                                                <td>Follow us on</td>
                                                <td>
                                                    <ul className="person-social-icons">
                                                        {mb?.team?.facebookLink ? 
                                                            <li>
                                                            <a className="active" href={`${mb?.team?.facebookLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faFacebook}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                        {mb?.team?.twitterLink ? 
                                                            <li>
                                                            <a className="active" href={`${mb?.team?.twitterLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faTwitter}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                        {mb?.team?.instagramLink ? 
                                                            <li>
                                                            <a className="active" href={`${mb?.team?.instagramLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faInstagram}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                        {mb?.team?.linkedinLink ? 
                                                            <li>
                                                            <a className="active" href={`${mb?.team?.linkedinLink?.url}`} target="_blank" rel="noreferrer">
                                                                <FontAwesomeIcon
                                                                    icon={faLinkedin}
                                                                />
                                                            </a>
                                                            </li>
                                                        : ""}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
        </>
    )
}